/* eslint-disable max-nested-callbacks */
/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'

import i18n from 'simple-react-i18n'
import { Avatar, Grid, styled, LinearProgress, Divider } from '@mui/material'
import { withStyles } from '@mui/styles'
import { indigo } from '@mui/material/colors'
import { compact, groupBy, isEmpty, orderBy, uniqBy } from 'lodash'
import AgriAction from '../../agri/actions/AgriAction'
import DtoInstallation from '../../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../../referencials/installations/actions/InstallationsAction'
import DtoUsageCategory from '../../referencials/dto/DtoUsageCategory'
import OuvrageCard from '../../components/cards/OuvrageCard'
import RetenueCard from '../../components/cards/RetenueCard'
import EquipmentCard from '../../components/cards/EquipmentCard'
import DtoSandreCode from '../../referencials/dto/DtoSandreCode'
import DtoExploitation from '../../agri/dto/exploitation/DtoExploitation'
import Accordeon from '../../components/Accordeon'
import DtoIntervenantDeclaration from '../../agri/dto/enquete/DtoIntervenantDeclaration'
import ComptageCard from '../../components/cards/ComptageCard'
import DtoVariousMateriel from '../../referencials/installations/dto/DtoVariousMateriel'
import DtoVariousMatSituation from '../../referencials/installations/dto/DtoVariousMatSituation'
import { formatMilliers } from '../../../../utils/StringUtil'
import ContactCard from '../../components/cards/ContactCard'
import ReferencialAction from '../../referencials/actions/ReferencialAction'
import DtoSamplingPointDecla from '../../agri/dto/exploitation/DtoSamplingPointDecla'
import DtoDeclaration from '../../agri/dto/enquete/DtoDeclaration'
import HomeAction from '../../../offline/actions/HomeAction'
import { MainButton } from '../../components/styled/buttons'
import DtoContact from '../../referencials/dto/DtoContact'
import ContactAction from '../../contact/actions/ContactAction'
import DtoTankDeclaration from '../../agri/dto/enquete/DtoTankDeclaration'
import { isLargeWindow } from '../../../../utils/LocalStorageUtils'
import { getMiniDate, getMiniDateNoYear } from '../../../../utils/DateUtil'
import { getLogin } from '../../../../utils/UserUtils'
import ToastrAction from '../../components/toasters/ToastrAction'
import { hasValue } from '../../../../utils/NumberUtil'
import DtoEnquete from '../../agri/dto/enquete/DtoEnquete'
import { getSetting } from '../../../../utils/FormUtils'
import ModalEditPoint from '../../points/ModalEditPoint'
import ModalEditPump from '../../referencials/materiels/components/ModalEditPump'
import ModalEditCounter from '../../referencials/materiels/components/ModalEditCounter'
import MaterielAction from '../../referencials/materiels/actions/MaterielAction'
import ModalRemovePoint from './modals/ModalRemovePoint'
import ModalAddEquipements from './modals/ModalAddEquipements'
import { getEvolValue } from '../../../../utils/AgriUtils'
import StyledCard from '../../components/cards/StyledCard'
import { POINT_STATUS_DECLARATION } from '../../agri/constants/AgriConstants'

const styles = (theme) => ({
    blue: {
        color: theme.palette.getContrastText(indigo[500]),
        backgroundColor: indigo[500],
    },
})

const GridItem = styled(Grid)({
    paddingTop: 10,
})

const ChronicleItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
})

const TYPE_ESTIM = 1
const TYPE_INDEX = 2

class PointDetailsDecla extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openModalOuvrage: false,
            openModalOwner: false,
            owner: {},
            openModalRetenue: false,
            openModalEquipement: false,
            equipement: {},
            retenueToUpdate: false,
            openModalRemove: false,
            point: {},
            installation: {},
            usages: [],
            usagesCurrentYear: [],
            pointLoaded: false,
            readMode: false,
        }
    }

    componentDidMount() {
        this.props.fetchUsages().then(() => {
            this.setState({ dataLoaded: true })
        })
        const { match, exploitation } = this.props
        this.props.fetchInstallation(match.params.id).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => {
                    this.getDeclaration()
                })
            } else {
                this.getDeclaration()
            }
        })
        window.scrollTo(0, 0)
    }

    getDeclaration = () => {
        const { exploitation } = this.props
        this.setState({ pointLoaded: false })
        this.props.fetchDeclarationByExploitationId(exploitation.idExploitation).then(() => {
            const { declaration, contacts } = this.props
            if (declaration.idDeclaration) {
                this.props.fetchSurvey(declaration.idSurvey).then(() => {
                    this.props.fetchDeclarationContributors(declaration.idSurvey).then(() => {
                        if (!contacts.length) {
                            this.props.fetchContacts().then(() => {
                                this.setContributors(declaration)
                            })
                        } else {
                            this.setContributors(declaration)
                        }
                    })
                })
            } else {
                this.props.push('/home')
            }
        })
    }

    formatContact = (contact, updatedContact) => {
        if (updatedContact) {
            return {
                ...contact,
                ...updatedContact,
                address: updatedContact.road,
                additionalAddress: updatedContact.addressComplement,
                desktopTel: updatedContact.fax,
                postalBox: updatedContact.postalCode,
            }
        }
        return contact
    }

    setContributors = (declaration) => {
        this.setState({ declaration })
        const { installation, exploitation, match, contacts, declarationContributors, survey } = this.props
        const point = declaration
            ? declaration.link_declarationInstallation.find(
                (p) => p.idInstallation === parseInt(match.params.id),
            )
            : new DtoSamplingPointDecla({})
        const linkPoint = exploitation.link_samplings ? exploitation.link_samplings.find((p) => p.idInstallation === point.idInstallation) : null
        const ownerUpdated = declarationContributors.find((dc) => dc.link_installations.find((cI) => cI.idInstallation === installation.id && cI.surveyId === declaration.idSurvey))
        const id = ownerUpdated ? (ownerUpdated.idContributorTemp || ownerUpdated.idContributor || ownerUpdated.id) : installation.ownerCode
        const owner = ownerUpdated && ownerUpdated.idContributorTemp ? {} : (contacts.find((c) => c.id === id) || {})
        const ownerFormatted = this.formatContact(owner, ownerUpdated)
        this.setState({
            installation,
            point,
            linkPoint,
            usages: point.link_usages,
            owner: ownerFormatted,
            usagesCurrentYear: point.link_usagesCurrent,
            pointLoaded: true,
            readMode: declaration.statusCode >= 4 || survey.statusCode === 3,
        })
        this.props.setTitle([{
            title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
            href: '/enquete',
        }])
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    onCancelEquipment = () => {
        this.toggleState('openModalEquipement')
    }

    onSaveEquipment = (link_equipments, link_pumps, link_counters) => {
        const { declaration, match } = this.props
        this.setState({ pointLoaded: false })
        const link_declarationInstallation = [
            ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== parseInt(match.params.id)),
            {
                ...declaration.link_declarationInstallation.find((p) => p.idInstallation === parseInt(match.params.id)),
                link_equipments,
            }
        ]
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation,
            link_pumps,
            link_counters,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.setContributors(this.props.declaration)
        })
        this.toggleState('openModalEquipement')
    }

    getListUsages = (current) => {
        const { usagesCategories } = this.props
        const {
            usagesCurrentYear,
            usages,
        } = this.state
        const usagesSelected = current ? usagesCurrentYear : usages
        const agriUsages = []
        const nonAgriUsages = []
        usagesSelected.forEach((u) => {
            const usageCat = usagesCategories.find((uCat) => uCat.idUsage === u.idUsage)
            if (usageCat && usageCat.category === 1) {
                agriUsages.push(u)
            } else if (usageCat && usageCat.category !== 1) {
                nonAgriUsages.push(u)
            }
        })

        let totalAgri = 0
        if (agriUsages.length) {
            agriUsages.forEach((u) => {
                totalAgri = totalAgri + (isNaN(u.requestedYearVolume) ? 0 : u.requestedYearVolume)
            })
        }
        let totalNonAgri = 0
        if (nonAgriUsages.length) {
            nonAgriUsages.forEach((u) => {
                totalNonAgri = totalNonAgri + (isNaN(u.requestedYearVolume) ? 0 : u.requestedYearVolume)
            })
        }

        return (
            <Grid
                container
                direction='column'
                justifyContent='center'
                onClick={() => this.goToUsages(current ? 'reel' : 'previ')}
            >
                <p><b>{`${i18n.agri} : `}</b>{`${agriUsages.length} ${agriUsages.length > 1 ? i18n.usages : i18n.usage} (${formatMilliers(totalAgri) || 0} m3)`}</p>
                <p><b>{`${i18n.nonAgri} : `}</b>{`${nonAgriUsages.length} ${nonAgriUsages.length > 1 ? i18n.usages : i18n.usage} (${formatMilliers(totalNonAgri) || 0} m3)`}</p>
            </Grid>
        )
    }

    onValidePoint = (declaration) => {
        this.props.push({
            pathname: '/enquete',
            step: 3,
            declaration: {
                ...declaration,
            },
        })
    }

    // onSaveOwner = (owner) => {
    //     const { installation, declaration, exploitation, contacts } = this.props
    //     const originalOwner = contacts.find((c) => c.id === installation.ownerCode)
    //     this.toggleState('openModalOwner')
    //     this.props.updateDeclarationContributor(
    //         declaration.idSurvey,
    //         new DtoIntervenantDeclaration({
    //             ...owner,
    //             idContributor: originalOwner ? originalOwner.id : null,
    //             idExploitation: exploitation.idExploitation,
    //             idInstallation: installation.id,
    //             surveyId: declaration.idSurvey,
    //             contactType: 2,
    //             mode: owner.idContributorTemp || !originalOwner ? 'c' : 'u',
    //         }),
    //     )
    //         .then(() => {
    //             this.getDeclaration()
    //         })
    // }

    onSaveOwner = (ownerUpdated) => {
        const { installation, declaration } = this.props
        const id = ownerUpdated.idContributorTemp || ownerUpdated.idContributor || ownerUpdated.id

        const newLink = {
            surveyId: declaration.idSurvey,
            idInstallation: installation.id,
            updateLogin: getLogin(),
        }

        const ownerFormatted = {
            ...ownerUpdated,
            road: ownerUpdated.road,
            addressComplement: ownerUpdated.addressComplement,
            fax: ownerUpdated.desktopTel,
            postalCode: ownerUpdated.postalBox,
            idContributor: id,
            surveyId: declaration.idSurvey,
            contactType: 2,
        }
        // si on utilise un contact existant
        if (!isEmpty(ownerUpdated)) {
            if (id) {
                this.setState({ pointLoaded: false })
                this.props.updateDeclarationContributor(declaration.idSurvey, {
                    ...ownerFormatted,
                    link_installations: [
                        ...(ownerUpdated.link_installations || []),
                        {
                            ...newLink,
                            idContributor: id,
                            isTemp: !!ownerUpdated.idContributorTemp,
                        },
                    ],
                }).then(() => {
                    this.getDeclaration()
                })
            } else {
                this.setState({ pointLoaded: false })
                this.props.updateDeclarationContributor(declaration.idSurvey, ownerFormatted).then((idReturned) => {
                    this.props.updateDeclarationContributor(declaration.idSurvey, {
                        ...ownerFormatted,
                        idContributorTemp: idReturned,
                        link_installations: [
                            ...(ownerUpdated.link_installations || []),
                            {
                                ...newLink,
                                idContributor: idReturned,
                                isTemp: true,
                            },
                        ],
                    }).then(() => {
                        this.getDeclaration()
                    })
                })
            }
            this.toggleState('openModalOwner')
        } else {
            ToastrAction.warning('Veuillez renseigner un propriétaire')
        }
    }

    onDeclareUnused = (declaration) => {
        const { point } = this.state
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: '/enquete',
                step: 3,
            })
        })
    }

    onDeclareUsed = (declaration) => {
        const { point } = this.state
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        points.push({
            ...pointToRemove,
            stateCode: POINT_STATUS_DECLARATION.USED,
        })
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: '/enquete',
                step: 3,
            })
        })
    }

    onDeclareAdjourn = (declaration) => {
        const { point } = this.state
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        points.push({
            ...pointToRemove,
            stateCode: POINT_STATUS_DECLARATION.ADJOURNED,
            link_usages: [],
        })
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: '/enquete',
                step: 3,
            })
        })
    }

    onRemovePoint = (declaration) => {
        const { point } = this.state
        const pointToRemove = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation)
        const points = declaration.link_declarationInstallation.filter(
            (p) => p.idInstallation !== point.idInstallation,
        )
        points.push({
            ...pointToRemove,
            stateCode: POINT_STATUS_DECLARATION.REMOVED,
        })
        const newDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: points,
        }
        this.props.updateDeclaration(newDeclaration).then(() => {
            this.toggleState('openModalRemove')
            this.props.push({
                pathname: '/enquete',
                step: 3,
            })
        })
    }

    goToUsages = (type) => {
        this.props.push(`/enquete/point/${this.props.match.params.id}/usages/${type}`)
    }

    getAssociatedResources = () => {
        const { linkPoint } = this.state
        return linkPoint ? linkPoint.link_samples[0] : null
    }

    getTypePrel = (key) => {
        switch (key) {
            case 1:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESO'
            case 2:
                return 'PREL_AGRI.NATURE_PRELEVEMENT_ESU'
            default:
                return 'PREL_AGRI.NATURE_PRELEVEMENT'
        }
    }

    getNature = () => {
        const associatedResources = this.getAssociatedResources()
        if (associatedResources) {
            const { codesSandre } = this.props
            const codeType = this.getTypePrel(associatedResources.sampleType)
            const codeFind = codesSandre.find((c) => c.field === codeType && c.code === associatedResources.sampleNature)
            return codeFind ? codeFind.name : ''
        }
        return ''
    }

    getOuvrages = () => {
        const { openModalOuvrage, point, linkPoint, readMode } = this.state
        if ((linkPoint.link_sampleCasings || []).length) {
            return (
                (linkPoint.link_sampleCasings || []).map((ouvrage) => {
                    const updatedOuvrage = (point.link_sampleCasings || []).find((o) => o.idInstallation === ouvrage.idInstallation)
                    const ouvrageDecla = {
                        ...ouvrage,
                        ...updatedOuvrage,
                    }
                    return (
                        <Grid item xs={12} id={ouvrageDecla.id} className='padding-top-1 padding-bottom-1' style={{ padding: '0 10' }}>
                            <OuvrageCard
                                title={i18n.struct}
                                editTitle={i18n.editStruct}
                                ouvrage={ouvrageDecla}
                                editMode={openModalOuvrage}
                                onCancel={this.onCancelOuvrage}
                                onSave={this.onSaveOuvrage}
                                toggleEditMode={() => this.toggleState('openModalOuvrage')}
                                accordeon
                                hideIcon={readMode}
                            />
                        </Grid>
                    )
                })
            )
        }
        return null
    }

    onCancelOuvrage = () => {
        this.toggleState('openModalOuvrage')
    }

    onSaveOuvrage = (ouvrage) => {
        const { point } = this.state
        const { declaration, exploitation } = this.props
        const updatedDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: [
                ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== point.idInstallation),
                {
                    ...point,
                    link_sampleCasings: [{
                        ...ouvrage,
                        idSurvey: declaration.idSurvey,
                        idExploitation: exploitation.idExploitation,
                        idInstallation: point.idInstallation,
                    }]
                },
            ],
        }
        this.props.updateDeclaration(updatedDeclaration).then(() => {
            this.getDeclaration()
            this.toggleState('openModalOuvrage')
        })
    }

    getRetenues = () => {
        const { linkPoint, point, readMode } = this.state
        const length = linkPoint.link_sampleTanks.length
        const title = length > 1 ? `${i18n.waterRetentions} (${length} ${i18n.elements})` : `${i18n.waterRetention} (${length} ${i18n.element})`
        if (length) {
            return (
                <GridItem item xs={12} style={{ padding: '10 10 0 10' }}>
                    <Accordeon title={title}>
                        <Grid container direction='column' className='padding-top-1 padding-bottom-1'>
                            {linkPoint.link_sampleTanks.map((retenue) => {
                                const updatedRetenue = point.link_sampleTanks.find((o) => o.idTank === retenue.idTank)
                                const retenueDecla = updatedRetenue ? {
                                    ...retenue,
                                    ...new DtoTankDeclaration(updatedRetenue),
                                    link_periodes: updatedRetenue.link_periodes && updatedRetenue.link_periodes.length ? [...updatedRetenue.link_periodes] : [...retenue.link_periodes],
                                } : retenue
                                return (
                                    <RetenueCard
                                        id={retenueDecla.idTank}
                                        title={retenueDecla.id}
                                        editTitle={i18n.editRetentionInfo}
                                        retenue={retenueDecla}
                                        toggleEditMode={() => this.editRetenue(retenueDecla)}
                                        hideIcon={readMode}
                                    />
                                )
                            })}
                        </Grid>
                    </Accordeon>
                </GridItem>
            )
        }
        return ''
    }

    onCancelRetenue = () => {
        this.setState({ retenueToUpdate: false })
        this.toggleState('openModalRetenue')
    }

    onSaveRetenue = (retenue) => {
        const { point } = this.state
        const { declaration, exploitation } = this.props
        const updatedDeclaration = {
            ...declaration,
            lastStep: declaration.lastStep >= 3 ? declaration.lastStep : 3,
            link_declarationInstallation: [
                ...declaration.link_declarationInstallation.filter((p) => p.idInstallation !== point.idInstallation),
                {
                    ...point,
                    link_sampleTanks: [
                        ...point.link_sampleTanks.filter((t) => t.idTank !== retenue.idTank),
                        {
                            ...retenue,
                            idSurvey: declaration.idSurvey,
                            idExploitation: exploitation.idExploitation,
                            idInstallation: point.idInstallation,
                            link_periodes: (retenue.link_periodes || []).map((l) => {
                                return {
                                    ...l,
                                    idTank: retenue.idTank,
                                    idSurvey: declaration.idSurvey,
                                    updateLogin: getLogin(),
                                }
                            }),
                        }]
                },
            ],
        }
        this.props.updateDeclaration(updatedDeclaration).then(() => {
            this.getDeclaration()
            this.setState({ retenueToUpdate: false })
            this.toggleState('openModalRetenue')
        })
    }

    editRetenue = (retenue) => {
        this.setState({ retenueToUpdate: retenue })
        this.toggleState('openModalRetenue')
    }

    getExploitationEquipments = () => {
        const { variousMatSituations, exploitation, declaration, variousMateriels } = this.props
        const { point } = this.state
        const matsSituPoint = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 &&
            m.siteCode === point.idInstallation &&
            variousMateriels.find((vM) => vM.id === m.idVarious && (!vM.administrator || vM.administrator === declaration.idContributor))
        ) || []
        let matsSituOthers = []
        variousMatSituations.forEach((m) => {
            if (!matsSituPoint.find((mP) => mP.idVarious === m.idVarious) &&
                !matsSituOthers.find((mO) => mO.idVarious === m.idVarious) &&
                m.siteType === 7
                && exploitation.link_samplings.find((p) => m.siteCode === p.idInstallation) &&
                variousMateriels.find((vM) => vM.id === m.idVarious && (!vM.administrator || vM.administrator === declaration.idContributor))) {
                matsSituOthers.push(m)
            }
        })
        return uniqBy([...matsSituPoint, ...matsSituOthers], v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join())
    }

    getUpdatedMats() {
        const { variousMatSituations, declaration } = this.props
        const { point } = this.state

        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation).link_equipments || []
        const materielsSelected = this.getExploitationEquipments().filter((m) => m.siteCode === point.idInstallation) // pompes installées initialement
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'c') // pompes ajoutées
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd') // pompes supprimées

        const newSelectedMatsSitu = compact(newMats.map((mLink) => {
            return variousMatSituations.find((mSitu) => mSitu.idVarious === mLink.idMat)
        }))
        const mergedSelectedMatSitu = compact([ // Pompes actuellement sur le point
            ...materielsSelected.filter((m) => !deletedMats.find((mLink) => m.idVarious === mLink.idMat)),
            ...newSelectedMatsSitu,
        ])

        const linkAssignments = link_equipments.filter((linkE) => linkE.assignmentRate).map((linkE) => {
            return {
                idMat: linkE.idMat,
                assignmentRate: linkE.assignmentRate,
            }
        })

        let linkCounters = link_equipments.filter((linkE) => linkE.idMatAttachment).map((linkE) => { // Liens entre pompes et compteurs
            return {
                idPump: linkE.idMat,
                idCounter: linkE.idMatAttachment,
                mode: linkE.mode,
            }
        })
        mergedSelectedMatSitu.forEach((mat) => {
            const counterSitu = variousMatSituations.find((mSitu) => mSitu.siteType === 8 && mat.idVarious === mSitu.siteCode)
            if (counterSitu && !linkCounters.find((linkC) => linkC.idPump === counterSitu.siteCode && linkC.idCounter === counterSitu.idVarious)) {
                linkCounters.push({
                    idPump: counterSitu.siteCode,
                    idCounter: counterSitu.idVarious,
                    mode: 'u'
                })
            }
        })
        return {
            mergedSelectedMatSitu: uniqBy(mergedSelectedMatSitu, v => [v.idVarious, v.siteCode, v.siteCode, v.siteType, v.statusCode].join()),
            linkAssignments,
            linkCounters,
        }
    }

    onChangePump = (obj) => {
        this.setState(({ pump }) => ({ pump: { ...pump, ...obj } }))
    }

    onSavePump = () => {
        const { pump } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(pump).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.getDeclaration()
                this.setState({ editPump: false, pump: null })
            })
        })
    }

    getModalEditPump = () => {
        const { pump, editPump } = this.state
        if (editPump) {
            return (
                <ModalEditPump
                    open={editPump}
                    toggleModal={() => this.setState({ editPump: false, pump: null })}
                    onChange={this.onChangePump}
                    onSave={this.onSavePump}
                    pump={pump}
                />
            )
        }
        return null
    }

    onChangeCounter = (obj) => {
        this.setState(({ counter }) => ({ counter: { ...counter, ...obj } }))
    }

    onSaveCounter = () => {
        const { counter } = this.state
        const { match } = this.props
        this.props.updateVariousMateriel(counter).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.getDeclaration()
                this.setState({ editCounter: false, counter: null })
            })
        })
    }

    getModalEditCounter = () => {
        const { counter, editCounter } = this.state
        if (editCounter) {
            return (
                <ModalEditCounter
                    open={editCounter}
                    toggleModal={() => this.setState({ editCounter: false, counter: null })}
                    onChange={this.onChangeCounter}
                    onSave={this.onSaveCounter}
                    counter={counter}
                />
            )
        }
        return null
    }

    getEquipments = () => {
        const {
            variousMatSituations,
            variousMateriels,
            declaration,
            exploitation,
            match,
        } = this.props
        const { readMode, editPump, editCounter } = this.state
        if (variousMatSituations.length && variousMateriels.length) {
            const { mergedSelectedMatSitu = [], linkAssignments = [], linkCounters = [] } = this.getUpdatedMats()
            const length = mergedSelectedMatSitu.length
            const title = length > 1 ? `${i18n.materiels} (${length} ${i18n.elements})` : `${i18n.materiel} (${length} ${i18n.element})`
            return (
                <GridItem item xs={12} style={{ padding: '10 10 0 10' }}>
                    <Accordeon title={title} showIcon={!readMode} onClickIcon={() => this.toggleState('openModalEquipement')} style={{ padding: '0 10' }}>
                        <Grid container direction='column' className='padding-top-1 padding-bottom-1'>
                            {mergedSelectedMatSitu.map((d) => {
                                const matPump = variousMateriels.find((m) => m.id === d.idVarious) || {}
                                const pump = matPump.pump || {}
                                const pumpAssignmentRate = linkAssignments.find((lA) => lA.idMat === d.idVarious && !lA.idMatAttachment)
                                const pumpRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 1 && m.idElement1 === parseInt(match.params.id) && m.idElement2 === d.idVarious) || {}
                                const pompeInfos = {
                                    ...variousMatSituations.find((m) => m.siteType === 8 && m.siteCode === d.idVarious),
                                    ...matPump,
                                    ...pump,
                                    ...declaration.link_pumps.find((p) => p.id === d.idVarious),
                                    assignmentRate: pumpAssignmentRate ? pumpAssignmentRate.assignmentRate : pumpRep.repartition,
                                }
                                const allCompteursInfo = compact(linkCounters.filter((lC) => lC.idPump === d.idVarious && lC.mode !== 'd').map((lC) => {
                                    const matCounter = variousMateriels.find((c) => c.id === lC.idCounter)
                                    const counterAssignmentRate = linkAssignments.find((lA) => lA.idMat === d.idVarious && lA.idMatAttachment === lC.idCounter)
                                    const counterRep = exploitation.link_repartition_materiels.find((m) => m.linkType === 2 && m.idElement1 === d.idVarious && m.idElement2 === lC.idCounter) || {}
                                    if (matCounter) {
                                        const counter = matCounter.counter || {}
                                        return {
                                            ...matCounter,
                                            ...counter,
                                            ...declaration.link_counters.find((c) => c.id === lC.idCounter),
                                            assignmentRate: counterAssignmentRate ? counterAssignmentRate.assignmentRate : counterRep.repartition,
                                        }
                                    }
                                    return null
                                }))
                                return (
                                    <>
                                        <EquipmentCard
                                            title={`${i18n.equipementPompage} : ${pompeInfos.reference}`}
                                            data={pompeInfos}
                                            toggleEditMode={() => this.setState({ editPump: !editPump, pump: pompeInfos })}
                                        />
                                        {allCompteursInfo.map((counter) => (
                                            <ComptageCard
                                                title={`${i18n.countingDevice} : ${counter.reference}`}
                                                data={counter}
                                                colorTitle='white'
                                                toggleEditMode={() => this.setState({ editCounter: !editCounter, counter })}
                                            />
                                        ))}
                                    </>
                                )
                            })}
                        </Grid>
                    </Accordeon>
                </GridItem>
            )
        }
        return ''
    }

    getMateriels = () => {
        const { declaration, match } = this.props
        const link_equipments = declaration.link_declarationInstallation.find((p) => p.idInstallation === parseInt(match.params.id)).link_equipments || []
        const newMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode !== 'd')
        const deletedMats = link_equipments.filter((mLink) => !mLink.idMatAttachment && mLink.mode === 'd')
        const deletedCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode === 'd')
        const exploitationPumps = declaration.link_exploitationMateriel.filter((m) => m.siteType === 7 && m.siteCode === parseInt(match.params.id) && !deletedMats.find((mLink) => m.idVarious === mLink.idMat)) || []
        const mergedPumpsIds = uniqBy([
            ...exploitationPumps.map((m) => m.idVarious),
            ...newMats.map((m) => m.idMat)
        ], obj => obj)
        const exploitationCountersId = compact(mergedPumpsIds.flatMap((pumpId) => {
            const pumpSitu = declaration.link_exploitationMateriel.filter((situ) => situ.siteType === 8 && situ.siteCode === pumpId && !deletedCounters.find((mLink) => pumpId === mLink.idMat))
            if (pumpSitu) {
                return pumpSitu.map((s) => s.idVarious)
            }
            return null
        }))
        const newCounters = link_equipments.filter((mLink) => mLink.idMatAttachment && mLink.mode !== 'd' && mergedPumpsIds.includes(mLink.idMat))
        const mergedCountersIds = uniqBy([
            ...exploitationCountersId,
            ...newCounters.map((m) => m.idMatAttachment)
        ], obj => obj)
        return [mergedPumpsIds, mergedCountersIds]
    }

    getChroniclesByType = (chroniclesByType, type, readingCoefficient = 1) => {
        const { survey: { year } } = this.props
        if (chroniclesByType.length) {
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            return (
                <>
                    <ChronicleItem item className='italic' xs={3}>
                        {type === TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                    </ChronicleItem>
                    <ChronicleItem item className='italic' xs={3}>
                        {type === TYPE_ESTIM ? i18n.endDate : ''}
                    </ChronicleItem>
                    <ChronicleItem item className='italic' xs={3}>
                        {type === TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                    </ChronicleItem>
                    <ChronicleItem item className='italic-label' xs={3}>
                        {`${type === TYPE_ESTIM ? 'Cumul' : i18n.evolution} en m3`}
                    </ChronicleItem>
                    {orderedChronicles.map((chronicle, i) => {
                        const valueEvol = i !== orderedChronicles.length - 1 ? getEvolValue(type, orderedChronicles, chronicle, i) : null
                        return (i > 4) ? null : (
                            <>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''}>
                                    {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''}>
                                    {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''}>
                                    {formatMilliers(chronicle.value) || 0}
                                </ChronicleItem>
                                <ChronicleItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange' } : {}}>
                                    {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs(valueEvol * readingCoefficient) || 0))}` : ''}
                                </ChronicleItem>
                            </>
                        )
                    })}
                    {orderedChronicles.length > 5 && <ChronicleItem item xs={11}>...</ChronicleItem>}
                    <ChronicleItem item xs={11}>
                        <Divider />
                    </ChronicleItem>
                </>
            )
        }
        return null
    }

    getChronicles = () => {
        const { declaration, variousMateriels, exploitation, match } = this.props
        const [pumpsId, countersId] = this.getMateriels()
        const allIds = [...pumpsId, ...countersId]
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === parseInt(match.params.id)) || {}
        const groupedByMat = Object.values(groupBy(declaration.link_chronicles.filter((c) => allIds.includes(c.idMat) && (!linkPoint.startDate || c.measureDate >= linkPoint.startDate) && (!linkPoint.endDate || c.measureDate < linkPoint.endDate)), 'idMat'))
        return compact(groupedByMat.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === TYPE_INDEX)
            const estimateChronicles = chronicles.filter((c) => c.measureType === TYPE_ESTIM)
            const counterUpdated = declaration.link_counters.find((c) => c.id === variousMat.id) || {}
            const readingCoefficient = counterUpdated.readingCoefficient || variousMat?.counter?.readingCoefficient
            return (
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='center'
                    onClick={() => this.goToUsages('reel')}
                >
                    <ChronicleItem item xs={12} className='bold' style={{ textAlign: 'start' }}>
                        {variousMat.reference}
                    </ChronicleItem>
                    {this.getChroniclesByType(indexChronicles, TYPE_INDEX, readingCoefficient)}
                    {this.getChroniclesByType(estimateChronicles, TYPE_ESTIM)}
                </Grid>
            )
        }))
    }

    onChange = (obj) => {
        this.setState(({ installation }) => ({ installation: { ...installation, ...obj } }))
    }

    onCancel = () => {
        this.getDeclaration()
        this.setState({ editPoint: false })
    }

    onSave = () => {
        const { installation } = this.state
        const { match } = this.props
        this.props.updateInstallation(installation).then(() => {
            this.props.fetchInstallation(match.params.id).then(() => {
                this.getDeclaration()
                this.setState({ editPoint: false })
            })
        })
    }

    getModalEditPoint = () => {
        const { installation, editPoint, linkPoint } = this.state
        if (editPoint) {
            return (
                <ModalEditPoint
                    open={editPoint}
                    toggleModal={() => this.setState({ editPoint: false })}
                    onChange={this.onChange}
                    onSave={this.onSave}
                    point={installation}
                    nature={this.getNature()}
                    stateCode={linkPoint.stateCode}
                />
            )
        }
        return null
    }

    render() {
        const {
            openModalOwner,
            openModalRetenue,
            openModalRemove,
            openModalEquipement,
            point,
            installation,
            owner,
            retenueToUpdate,
            pointLoaded,
            readMode,
            editPoint,
        } = this.state
        const { classes, declaration = {}, survey } = this.props
        const title = `${i18n.consos} ${survey.year ? survey.year - 1 : ''}`

        if (pointLoaded) {
            return (
                <>
                    <div style={{ padding: '0 10' }}>
                        <Grid
                            container
                            className='borderBottom-Grey'
                            alignItems='center'
                            style={{ padding: 8 }}
                        >
                            <Grid item xs={2}>
                                <Avatar className={classes.blue}>3/4</Avatar>
                            </Grid>
                            <Grid item xs={10}>
                                <h3 className='italic'>Je vérifie mon point de prélèvement</h3>
                            </Grid>
                        </Grid>
                    </div>
                    <StyledCard
                        title={`${i18n.descriptive} - ${installation.code}`}
                        color='white'
                        styleContainer={{ paddingTop: 10 }}
                        styleTitle={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}
                        toggleEditMode={() => this.setState({ editPoint: !editPoint })}
                        styleContent={{ padding: '0 8' }}
                        showIcon
                        newStyle
                    >
                        <p>{i18n.name} : {installation.name || ''}</p>
                        <p>{i18n.naturePrel} : {this.getNature()}</p>
                        {hasValue(installation.descriptive) && <p>{i18n.descriptive} : {installation.descriptive || ''}</p>}
                        {hasValue(installation.comments) && <p>{i18n.comment} : {installation.comments || ''}</p>}
                    </StyledCard>
                    {((getSetting(survey.link_params, 'usagesReal') || '1') === '1' || (getSetting(survey.link_params, 'consos') || '1') === '1') && (
                        <GridItem item xs={12}>
                            <StyledCard
                                title={title}
                                styleTitle={{ borderTop: '1px solid black', borderBottom: '1px solid black' }}
                                className='clickable'
                                toggleEditMode={() => this.goToUsages('reel')} icon={readMode ? 'remove_red_eye' : 'edit'}
                                styleContainer={{ padding: '0 10' }}
                                showIcon
                            >
                                {(getSetting(survey.link_params, 'consos') || '1') !== '0' && this.getChronicles()}
                                {(getSetting(survey.link_params, 'usagesReal') || '1') !== '0' && this.getListUsages(true)}
                            </StyledCard>
                        </GridItem>
                    )}
                    {(getSetting(survey.link_params, 'usagesPrevi') || '1') !== '0' && (
                        <GridItem item xs={12}>
                            <StyledCard
                                title={`Prévisions ${survey.year}`}
                                className='clickable'
                                toggleEditMode={() => this.goToUsages('previ')} icon={readMode ? 'remove_red_eye' : 'edit'}
                                styleContent={{ padding: '0 8' }}
                                showIcon
                            >
                                {this.getListUsages(false)}
                            </StyledCard>
                        </GridItem>
                    )}
                    <Grid container alignItems='center' style={{ paddingBottom: 60 }}>
                        {this.getEquipments()}
                        <Grid item xs={12} className='padding-bottom-1' style={{ padding: 10 }}>
                            <ContactCard
                                title={i18n.owner}
                                editTitle={i18n.editOwnerInfo}
                                contact={owner}
                                editMode={openModalOwner}
                                onSave={this.onSaveOwner}
                                onCancel={() => this.toggleState('openModalOwner')}
                                toggleEditMode={() => this.toggleState('openModalOwner')}
                                accordeon
                                expanded={!!owner}
                                hideIcon={readMode}
                            />
                        </Grid>
                        {this.getOuvrages()}
                        {this.getRetenues()}
                    </Grid>
                    <Grid
                        container
                        direction='row'
                        justifyContent='space-evenly'
                        alignItems='center'
                        className='borderTop-Grey'
                        style={{
                            position: 'fixed',
                            bottom: 0,
                            backgroundColor: 'white',
                            zIndex: 1000,
                            padding: '5px 10px',
                            minHeight: 70,
                        }}
                        sx={{
                            lg: { height: '70px' },
                            md: { height: !readMode ? '70px' : '120px' },
                            xs: { height: !readMode ? '70px' : '120px' },
                        }}
                    >
                        {!readMode && (
                            <Grid item xs={12} md={5}>
                                <MainButton
                                    reverse='true'
                                    onClick={() => this.toggleState('openModalRemove')}
                                >
                                    Changer le statut du point
                                </MainButton>
                            </Grid>
                        )}
                        <Grid item xs={12} md={5}>
                            <MainButton onClick={() => this.onValidePoint(declaration)}>
                                {readMode ? i18n.returnPreviousStep : i18n.validModifications}
                            </MainButton>
                        </Grid>
                    </Grid>
                    {openModalRemove && (
                        <ModalRemovePoint
                            open={openModalRemove}
                            onDeclareUsed={() => this.onDeclareUsed(declaration)}
                            onDeclareUnused={() => this.onDeclareUnused(declaration)}
                            onDeclareAdjourn={() => this.onDeclareAdjourn(declaration)}
                            onRemovePoint={() => this.onRemovePoint(declaration)}
                            onCancel={() => this.toggleState('openModalRemove')}
                            pointStatus={declaration.link_declarationInstallation.find((p) => p.idInstallation === point.idInstallation).stateCode}
                        />
                    )}
                    {openModalRetenue && (
                        <RetenueCard
                            title={i18n.waterRetention}
                            editTitle={i18n.editRetentionInfo}
                            retenue={retenueToUpdate}
                            editMode={openModalRetenue}
                            onCancel={this.onCancelRetenue}
                            onSave={this.onSaveRetenue}
                            toggleEditMode={() => this.toggleState('openModalRetenue')}
                        />
                    )}
                    {openModalEquipement && (
                        <ModalAddEquipements
                            open={openModalEquipement}
                            materiels={this.getExploitationEquipments()}
                            point={point}
                            declaration={declaration}
                            onSave={this.onSaveEquipment}
                            onCancel={this.onCancelEquipment}
                        />
                    )}
                    {this.getModalEditPoint()}
                    {this.getModalEditPump()}
                    {this.getModalEditCounter()}
                </>
            )
        }
        return <LinearProgress />
    }
}

PointDetailsDecla.propTypes = {
    classes: PropTypes.instanceOf(PropTypes.shape({})),
    match: PropTypes.instanceOf(PropTypes.shape({})),
    declaration: PropTypes.instanceOf(DtoDeclaration),
    exploitation: PropTypes.instanceOf(DtoExploitation),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    usagesCategories: PropTypes.arrayOf(PropTypes.instanceOf(DtoUsageCategory)),
    codesSandre: PropTypes.arrayOf(PropTypes.instanceOf(DtoSandreCode)),
    fetchExploitation: PropTypes.func,
    fetchSurvey: PropTypes.func,
    fetchInstallation: PropTypes.func,
    updateInstallation: PropTypes.func,
    updateDeclaration: PropTypes.func,
    fetchDeclarationByExploitationId: PropTypes.func,
    fetchUsages: PropTypes.func,
    fetchDeclarationContributors: PropTypes.func,
    updateVariousMateriel: PropTypes.func,
    fetchContacts: PropTypes.func,
    updateDeclarationContributor: PropTypes.func,
    contacts: PropTypes.arrayOf(PropTypes.instanceOf(DtoContact)),
    declarationContributors: PropTypes.arrayOf(PropTypes.instanceOf(DtoIntervenantDeclaration)),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    survey: PropTypes.instanceOf(DtoEnquete),
}

const mapStateToProps = (store) => {
    return {
        usagesCategories: store.ReferencialReducer.usagesCategories,
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        declaration: store.AgriReducer.declaration,
        exploitation: store.AgriReducer.exploitation,
        codesSandre: store.ReferencialReducer.codesSandre,
        contacts: store.ContactReducer.contacts,
        declarationContributors: store.AgriReducer.declarationContributors,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        survey: store.AgriReducer.survey,
    }
}

const mapDispatchToProps = {
    fetchUsages: ReferencialAction.fetchUsages,
    fetchExploitation: AgriAction.fetchExploitation,
    fetchSurvey: AgriAction.fetchSurvey,
    fetchDeclarationByExploitationId: AgriAction.fetchDeclarationByExploitationId,
    updateVariousMateriel: MaterielAction.updateVariousMateriel,
    updateDeclaration: AgriAction.updateDeclaration,
    fetchDeclarationContributors: AgriAction.fetchDeclarationContributors,
    fetchInstallation: InstallationsAction.fetchInstallation,
    updateInstallation: InstallationsAction.updateInstallation,
    setTitle: HomeAction.setTitle,
    fetchContacts: ContactAction.fetchContacts,
    updateDeclarationContributor: AgriAction.updateDeclarationContributor,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PointDetailsDecla))
