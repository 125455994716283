/* eslint-disable camelcase */
/* eslint-disable indent */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import PropTypes from 'prop-types'
import i18n from 'simple-react-i18n'
import { compact, groupBy, isNil, isNumber, orderBy, uniq } from 'lodash'
import { styled, Grid, LinearProgress, Divider, Icon } from '@mui/material'
import moment from 'moment'
import { MainButton } from '../components/styled/buttons'
import HomeAction from '../../offline/actions/HomeAction'
import AgriAction from '../agri/actions/AgriAction'
import DtoInstallation from '../referencials/installations/dto/DtoInstallation'
import InstallationsAction from '../referencials/installations/actions/InstallationsAction'
import DtoExploitation from '../agri/dto/exploitation/DtoExploitation'
import { formatMilliers } from '../../../utils/StringUtil'
import DtoVariousMatSituation from '../referencials/installations/dto/DtoVariousMatSituation'
import DtoVariousMateriel from '../referencials/installations/dto/DtoVariousMateriel'
import { isLargeWindow } from '../../../utils/LocalStorageUtils'
import DtoCMSEvent from '../cms/dto/DtoCMSEvent'
import CmsAction from '../cms/actions/CmsAction'
import { getDate, getMiniDate, getMiniDateNoYear } from '../../../utils/DateUtil'
import DtoDeclarationVolumes from '../agri/dto/enquete/DtoDeclarationVolumes'
import { hasValue } from '../../../utils/NumberUtil'
import MaterielAction from '../referencials/materiels/actions/MaterielAction'
import DtoMatEvent from '../referencials/materiels/dto/DtoMatEvent'
import DtoMatEventsType from '../referencials/materiels/dto/DtoMatEventsType'
import { HelpTooltip } from '../components/styled/texts'
import ModalMatEvent from './ModalMatEvent'
import ModalConso from './ModalConso'
import StyledCard from '../components/cards/StyledCard'
import { ReactComponent as NotFavLogo } from '../../../ressources/static/svg/Favori.svg'
import { lightColor } from '../components/styled/theme'
import ContactAction from '../contact/actions/ContactAction'
import { getLogin } from '../../../utils/UserUtils'
import ReferencialAction from '../referencials/actions/ReferencialAction'
import DtoWaterTurnRestriction from '../agri/dto/DtoWaterTurnRestriction'
import { getEvolValue } from '../../../utils/AgriUtils'
import { CHRONICLES_CONSTANTS } from '../agri/constants/AgriConstants'

const GridItem = styled(Grid)({
    padding: '4px !important',
    textAlign: 'center',
    alignSelf: 'center',
})

const GridVolume = styled(Grid)({
    padding: '4px !important',
    textAlign: 'right',
    alignSelf: 'center',
})

class PointConso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            contactsLoaded: false,
            idInstallation: parseInt(props.match.params.id),
            point: {},
            installation: {},
            openModalIndex: false,
            openModalEvent: false,
            chroniclePumpId: null,
            chronicleCounterId: null,
            numberOfLines: 5,
            year: new Date().getFullYear(),
            realConso: null,
            selectedEvent: null,
        }
    }

    componentDidMount() {
        const { exploitation } = this.props
        const { idInstallation, year } = this.state
        this.props.fetchCmsByCategory(5)
        this.props.fetchContacts().then(() => this.setState({ contactsLoaded: true }))
        this.props.fetchInstallation(idInstallation).then(() => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.fetchVolumes(idInstallation, year))
            } else {
                this.fetchVolumes(idInstallation, year)
            }
        })
        window.scrollTo(0, 0)
    }

    fetchVolumes = (idInstallation, year) => {
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            if (!exploitation.idExploitation) {
                this.props.fetchExploitation().then(() => this.setState({ realConso: res?.total, dataLoaded: true }))
            } else {
                this.setState({ realConso: res?.total, dataLoaded: true })
            }
            this.props.fetchBookmarks().then(() => this.setTitle(idInstallation))
        })
    }

    setTitle = (idInstallation) => {
        const { installation, citiesIndex, bookmarks } = this.props
        const fav = bookmarks.find((b) => b.stationType === 7 && b.identifiant === `${idInstallation}`)
        const city = citiesIndex[installation.townCode] || {}
        const bookmark = {
            login: getLogin(),
            identifiant: `${idInstallation}`,
            stationType: 7,
        }
        this.props.setTitle([{
            title: `Point ${installation.code || ''}${isLargeWindow() && installation.location ? ` - ${installation.location}` : ''}`,
            subTitle: city.name,
            href: `/consommation/point/${installation.id}`,
            icon: fav ? <Icon onClick={() => this.deleteBookmark(fav)}>start</Icon> : <NotFavLogo fill={lightColor} style={{ height: 20 }} onClick={() => this.createBookmark(bookmark)} />,
        }])
    }

    deleteBookmark = (fav) => {
        this.props.deleteBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    createBookmark = (fav) => {
        this.props.createBookmark(fav).then(() => {
            this.props.fetchBookmarks().then(() => this.setTitle(Number(fav.identifiant)))
        })
    }

    componentWillUnmount() {
        this.props.resetInstallation()
    }

    getMateriels = () => {
        const { variousMateriels, exploitation } = this.props
        const { idInstallation } = this.state
        const pointPumps = (exploitation.link_materiel || []).filter((m) => m.siteType === 7 && m.siteCode === idInstallation) || []
        const pointCounters = (exploitation.link_materiel || []).filter((m) => m.siteType === 8 && pointPumps.find(({ idVarious }) => idVarious === m.siteCode)) || []
        const pumpsIds = pointPumps.map(({ idVarious }) => idVarious)
        const countersIds = pointCounters.map(({ idVarious }) => idVarious)
        const materiels = variousMateriels.filter((c) => [...pumpsIds, ...countersIds].includes(c.id) && (!hasValue(c.administrator) || c.administrator === exploitation.operatorCode))
        return { pumpsIds, countersIds, materiels }
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] })
    }

    editModalIndex = (type, id) => {
        this.setState({ selectedMaterial: id, consoType: type, openModalIndex: true })
    }

    getColorRealConso = (authorizedVolume, realConso) => {
        if (!hasValue(authorizedVolume) || !hasValue(realConso)) {
            return 'black'
        }
        if (realConso > authorizedVolume) {
            return 'red'
        }
        if (((realConso / authorizedVolume) * 100) >= 80) {
            return 'orange'
        }
        return 'green'
    }

    getChroniclesByType = (chroniclesByType, type, materiel, readingCoefficient = 1) => {
        if (chroniclesByType.length) {
            const { numberOfLines, year } = this.state
            const { matEventsTypes } = this.props
            const orderedChronicles = orderBy(chroniclesByType, ['measureDate', 'endDate', 'value'], ['desc', 'desc', 'desc'])
            const currentChronicles = orderedChronicles.filter((c) => moment(c.measureDate).year() >= year)
            const pastChronicles = orderedChronicles.filter((c) => !c.event && moment(c.measureDate).year() < year)
            const props = {
                onClick: () => this.editModalIndex(type, materiel.id),
            }
            return {
                nbLines: currentChronicles.length,
                content: (
                    <>
                        <GridItem item className='italic' xs={3} {...props} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.startDate : i18n.statementDate}
                        </GridItem>
                        <GridItem item className='italic' xs={3} {...props} style={{ textAlign: 'start' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.endDate : ''}
                        </GridItem>
                        <GridItem item className='italic' xs={3} {...props} style={{ textAlign: 'end' }}>
                            {type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? i18n.estimateM3 : i18n.index}
                        </GridItem>
                        <GridItem item className='italic' xs={3} style={{ textAlign: 'end' }}>
                            {`${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'Cumul' : i18n.evolution} en m3`}
                        </GridItem>
                        {currentChronicles.slice(0, numberOfLines).map((chronicle, i) => {
                            if (chronicle.event) {
                                const onClickEvent = {
                                    onClick: () => {
                                        this.setState({ openModalEvent: true, selectedEvent: chronicle })
                                    },
                                }
                                return (
                                    <>
                                        <GridItem item xs={3} {...onClickEvent} style={{ textAlign: 'start' }}>
                                            {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                        </GridItem>
                                        <HelpTooltip title={chronicle.comment} arrow>
                                            <GridItem item xs={3} {...onClickEvent}><Icon>warning</Icon></GridItem>
                                        </HelpTooltip>
                                        <GridItem item xs={3} {...onClickEvent} style={{ textAlign: 'end' }}>
                                            {(matEventsTypes.find(({ id }) => id === chronicle.idType) || {}).name || ''}
                                        </GridItem>
                                        <GridItem item xs={3} />
                                    </>
                                )
                            }
                            const valueEvol = i !== currentChronicles.length - 1 ? getEvolValue(type, currentChronicles, chronicle, i) : null
                            return (
                                <>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'start' }}>
                                        {new Date(chronicle.measureDate).getFullYear() === year ? getMiniDateNoYear(chronicle.measureDate) : getMiniDate(chronicle.measureDate)}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'start' }}>
                                        {chronicle.endDate ? (new Date(chronicle.endDate).getFullYear() === year ? getMiniDateNoYear(chronicle.endDate) : getMiniDate(chronicle.endDate)) : ''}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} {...props} style={{ textAlign: 'end' }}>
                                        {formatMilliers(chronicle.value) || 0}
                                    </GridItem>
                                    <GridItem item xs={3} className={i === 0 ? 'bold' : ''} style={valueEvol < 0 ? { color: 'orange', textAlign: 'end' } : { textAlign: 'end' }}>
                                        {hasValue(valueEvol) ? ` ${valueEvol < 0 ? '-' : '+'} ${formatMilliers((Math.abs((valueEvol) * readingCoefficient) || 0))}` : ''}
                                    </GridItem>
                                </>
                            )
                        })}
                        {pastChronicles.length > 0 && <GridItem item xs={12}>...</GridItem>}
                        {pastChronicles.map((chronicle) => (
                            <>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'start' }}>
                                    {getDate(chronicle.measureDate)}
                                </GridItem>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'start' }}>
                                    {chronicle.endDate ? getDate(chronicle.endDate) : ''}
                                </GridItem>
                                <GridItem item xs={3} {...props} style={{ textAlign: 'end' }}>
                                    {`${formatMilliers(chronicle.value) || 0}${type === CHRONICLES_CONSTANTS.TYPE_ESTIM ? 'm3' : ''}`}
                                </GridItem>
                                <GridItem item xs={3}>
                                    {moment(chronicle.measureDate).year() !== year && <Icon>access_time</Icon>}
                                </GridItem>
                            </>
                        ))}
                        <GridItem item xs={11}>
                            <Divider />
                        </GridItem>
                    </>
                )
            }
        }
        return { nbLines: 0, content: null }
    }

    getChronicles = (groupedChronicles) => {
        const { variousMateriels, exploitation } = this.props
        return compact(groupedChronicles.map((chronicles) => {
            const variousMat = variousMateriels.find((mat) => mat.id === chronicles[0].idMat) || {}
            if (hasValue(variousMat.administrator) && variousMat.administrator !== exploitation.operatorCode) {
                return null
            }
            const indexChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_INDEX || c.event)
            const estimateChronicles = chronicles.filter((c) => c.measureType === CHRONICLES_CONSTANTS.TYPE_ESTIM)
            const readingCoefficient = variousMat?.counter?.readingCoefficient || 1
            const indexs = this.getChroniclesByType(indexChronicles, CHRONICLES_CONSTANTS.TYPE_INDEX, variousMat, readingCoefficient)
            const estimates = this.getChroniclesByType(estimateChronicles, CHRONICLES_CONSTANTS.TYPE_ESTIM, variousMat)
            return {
                nbLines: indexs.nbLines + estimates.nbLines,
                content: (
                    <Grid
                        item
                        container
                        direction='row'
                        justifyContent='center'
                        className='clickable'
                    >
                        <GridItem item xs={12} className='bold' style={{ textAlign: 'start' }}>
                            {variousMat.reference}{readingCoefficient && ` - ${i18n.coefLectureShort} ${readingCoefficient}`}{variousMat?.counter?.informative && ` (${i18n.informative})`}
                        </GridItem>
                        {indexs.content}
                        {estimates.content}
                    </Grid>
                )
            }
        }))
    }

    getVolumes = (pointVolumes, estimConso, realConso) => (
        <Grid
            item
            xs={12}
            container
            direction='row'
            alignItems='center'
        >
            <GridItem item xs={7} style={{ textAlign: 'start' }}>
                {i18n.requestedVolume}
            </GridItem>
            <GridVolume item xs={5} className='textRight valueLabel'>
                {isNumber(pointVolumes.askedVolume) ? `${formatMilliers(pointVolumes.askedVolume)}m3` : pointVolumes.askedVolume}
            </GridVolume>
            <GridItem item xs={7} style={{ textAlign: 'start' }}>
                {i18n.allocatedVolume}
            </GridItem>
            <GridVolume item xs={5} className='textRight valueLabel'>
                {isNumber(pointVolumes.attributedVolume) ? `${formatMilliers(pointVolumes.attributedVolume)}m3` : pointVolumes.attributedVolume}
            </GridVolume>
            <GridItem item xs={7} style={{ textAlign: 'start' }}>
                {i18n.authorizedVolume}
            </GridItem>
            <GridVolume item xs={5} className='textRight valueLabel'>
                {isNumber(pointVolumes.authorizedVolume) ? `${formatMilliers(pointVolumes.authorizedVolume)}m3` : pointVolumes.authorizedVolume}
            </GridVolume>
            <GridItem item xs={7} style={{ textAlign: 'start' }}>
                {i18n.estimConsumption}
            </GridItem>
            <GridVolume item xs={5} className='textRight valueLabel'>
                {hasValue(estimConso) ? `${formatMilliers(estimConso)}m3` : '-'}
            </GridVolume>
            <GridItem item xs={7} style={{ textAlign: 'start' }}>
                {i18n.realConsumption}
            </GridItem>
            <GridVolume item xs={5} className='textRight valueLabel' style={{ color: this.getColorRealConso(pointVolumes.authorizedVolume, realConso) }}>
                {hasValue(realConso) ? `${formatMilliers(realConso)}m3` : '-'}
            </GridVolume>
        </Grid>
    )

    getEstimConso = (groupedChronicles) => {
        const { year } = this.state
        return groupedChronicles.map((chroniques) => {
            return chroniques.filter((c) => c.measureType === 1 && moment(c.measureDate).year() >= year).reduce((acc, c) => acc + c.value, 0) || 0
        }).reduce((acc, c) => acc + c, 0)
    }

    onChangeYear = (year) => {
        const { idInstallation } = this.state
        const { exploitation } = this.props
        this.props.calculPointVolumeReal(idInstallation, exploitation.idExploitation, moment(`31/01/${year}`, 'DD/MM/YYYY').valueOf(), moment(`01/12/${year}`, 'DD/MM/YYYY').valueOf()).then((res) => {
            this.setState({ year, numberOfLines: 5, realConso: res?.total })
        })
    }

    getContent = (pumpsIds, countersIds) => {
        const { exploitation, exploitationVolumes, matEventsExploitation } = this.props
        const { idInstallation, year, realConso, numberOfLines } = this.state
        const linkPoint = exploitation.link_samplings.find((l) => l.idInstallation === idInstallation) || {}
        const volumes = exploitationVolumes.filter((v) => v.idInstallation === idInstallation && v.askedYear === year)
        const askedVolume = volumes.some((v) => !isNil(v.askedVolume)) ? volumes.reduce((acc, v) => acc + (v.askedVolume || 0), 0) : i18n.unknown
        const attributedVolume = volumes.some((v) => !isNil(v.attributedVolume)) ? volumes.reduce((acc, v) => acc + (v.attributedVolume || 0), 0) : i18n.unknown
        const authorizedVolume = volumes.some((v) => !isNil(v.authorizedVolume)) ? volumes.reduce((acc, v) => acc + (v.authorizedVolume || 0), 0) : i18n.unknown
        const pointVolumes = {
            askedVolume,
            attributedVolume,
            authorizedVolume,
        }
        const chroniclesMat = exploitation.link_chronicles.filter(({ idMat }) => [...pumpsIds, ...countersIds].includes(idMat))
        const pointChronicles = chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year &&
            (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
            (!linkPoint.endDate || measureDate < linkPoint.endDate)
        )
        const pointChroniclePrevYear = orderBy(chroniclesMat.filter(({ measureDate }) => new Date(measureDate).getFullYear() === year - 1 &&
            (!linkPoint.startDate || measureDate >= linkPoint.startDate) &&
            (!linkPoint.endDate || measureDate < linkPoint.endDate)
        ), ['measureDate', 'endDate'], 'desc')[0]
        const chroniclesToShow = pointChroniclePrevYear ? [...pointChronicles, pointChroniclePrevYear] : [...pointChronicles]
        // const lastChronicle = pointChronicles.length ? pointChronicles.reduce((max, chronique) => (max.measureDate > chronique.measureDate) ? max : chronique) : null
        const eventsMat = matEventsExploitation.filter(({ idMateriel }) => [...pumpsIds, ...countersIds].includes(idMateriel))
        const pointEvents = eventsMat.filter(({ eventDate }) => new Date(eventDate).getFullYear() === year)
        const pointEventsFormatted = pointEvents.map((e) => {
            return {
                ...e,
                idMat: e.idMateriel,
                measureDate: e.eventDate,
                event: true,
            }
        })

        const groupedByMat = Object.values(groupBy([...chroniclesToShow, ...pointEventsFormatted], 'idMat'))
        const estimConso = groupedByMat.length ? this.getEstimConso(groupedByMat) : null
        const chroniclesValues = this.getChronicles(groupedByMat)
        return (
            <>
                <Grid
                    item
                    xs={12}
                    container
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    style={{ padding: 10 }}
                >
                    <Grid item>
                        <MainButton reverse='true' onClick={() => this.onChangeYear(year - 1)}>
                            <Icon>keyboard_arrow_left</Icon>
                            {year - 1}
                        </MainButton>
                    </Grid>
                    <Grid item>
                        <b style={{ fontSize: '1.5em' }}>{year}</b>
                    </Grid>
                    <Grid item>
                        <MainButton
                            reverse='true'
                            onClick={() => this.onChangeYear(year + 1)}
                            className={year === new Date().getFullYear() ? 'hide' : ''}
                        >
                            {year + 1}
                            <Icon>keyboard_arrow_right</Icon>
                        </MainButton>
                    </Grid>
                    {/* {lastChronicle ? (
                        <span style={{ textAlign: 'center' }}>
                            {i18n.lastValueInDate}
                            {lastChronicle.measureType === 1 ? (
                                <h2 style={{ whiteSpace: 'pre-line' }}>{`${getMiniDate(lastChronicle.measureDate)} -> ${getMiniDate(lastChronicle.endDate)}\n${formatMilliers(lastChronicle.value) || 0}m3`}</h2>
                            ) : (
                                <h2>{`${getMiniDate(lastChronicle.measureDate)} - ${formatMilliers(lastChronicle.value) || 0}`}</h2>
                            )}
                        </span>
                    ) : (
                        <h2>Aucune donnée</h2>
                    )} */}
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    style={{ padding: '0 10 0 10' }}
                >
                    <Grid item xs={12}>
                        <StyledCard
                            subTitle='Volumes'
                            color='white'
                            styleContainer={{ padding: 0, borderTop: '1px solid black' }}
                        >
                            {this.getVolumes(pointVolumes, estimConso, realConso)}
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 10 }}>
                        <StyledCard
                            subTitle='Details du prélèvement'
                            color='white'
                            styleContainer={{ padding: 0, borderTop: '1px solid black' }}
                        >
                            <>
                                {chroniclesValues.map(c => c.content)}
                                <MainButton
                                    reverse='true'
                                    onClick={this.showMore}
                                    disabled={numberOfLines >= chroniclesValues.reduce((acc, c) => acc + c.nbLines, 0)}
                                >
                                    {i18n.showMoreHistorical}
                                </MainButton>
                            </>
                        </StyledCard>
                    </Grid>
                </Grid>
            </>
        )
    }

    onCancelIndex = () => {
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
        })
    }

    onSaveChronicles = (link_chronicles) => {
        const { exploitation } = this.props
        const updateExploitation = {
            ...exploitation,
            link_chronicles,
        }
        this.setState({
            selectedMaterial: null,
            consoType: null,
            openModalIndex: false,
            dataLoaded: false,
        })
        this.props.updateExploitation(updateExploitation).then(() => {
            this.props.fetchExploitation().then(() => this.setState({ dataLoaded: true }))
        })
    }

    showMore = () => {
        this.setState(({ numberOfLines }) => ({
            numberOfLines: numberOfLines + 5,
        }))
    }

    onDeleteEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        this.props.deleteMatEvent(event.id, () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)).then(() => {
            this.setState({ dataLoaded: true })
        })
    }

    onValidateEvent = (event) => {
        const { exploitation } = this.props
        this.setState({ dataLoaded: false, openModalEvent: false, selectedEvent: null })
        if (event.id) {
            this.props.updateMatEvent(event,
                () => this.props.fetchMatEventsByExploitation(exploitation.idExploitation)
            ).then(() => {
                this.setState({ dataLoaded: true })
            })
        } else {
            this.props.createMatEvent(event).then(() => {
                this.props.fetchMatEventsByExploitation(exploitation.idExploitation).then(() => {
                    this.setState({ dataLoaded: true })
                })
            })
        }
    }

    render() {
        const {
            openModalIndex,
            point,
            selectedMaterial,
            consoType,
            openModalEvent,
            dataLoaded, contactsLoaded,
            selectedEvent,
        } = this.state
        const { variousMateriels, variousMatSituations, exploitation } = this.props
        if (variousMatSituations.length && variousMateriels.length && dataLoaded && contactsLoaded && exploitation.idExploitation) {
            const { pumpsIds, countersIds, materiels } = this.getMateriels()
            const exploitationMatIds = materiels.map(({ id }) => id)

            return (
                <>
                    <Grid container alignItems='center' justifyContent='space-around'>
                        {this.getContent(pumpsIds, countersIds)}
                    </Grid>
                    {openModalIndex && (
                        <ModalConso
                            open={openModalIndex}
                            point={point}
                            pumpsIds={uniq(pumpsIds.filter((id) => exploitationMatIds.includes(id)))}
                            countersIds={uniq(countersIds.filter((id) => exploitationMatIds.includes(id)))}
                            selectedMaterial={selectedMaterial}
                            consoType={consoType}
                            onSave={this.onSaveChronicles}
                            onCancel={this.onCancelIndex}
                            chronicles={exploitation.link_chronicles}
                        />
                    )}
                    {openModalEvent && (
                        <ModalMatEvent
                            open={openModalEvent}
                            event={selectedEvent}
                            materiels={materiels}
                            onCancel={() => this.setState({ openModalEvent: false, selectedEvent: null })}
                            onDelete={this.onDeleteEvent}
                            onValidate={this.onValidateEvent}
                        />
                    )}
                </>
            )
        }
        return (
            <Grid container alignItems='center' justifyContent='space-around'>
                <Grid item xs={12} style={{ margin: '16px 0' }}>
                    <LinearProgress />
                </Grid>
            </Grid>
        )
    }
}

PointConso.propTypes = {
    match: PropTypes.instanceOf(PropTypes.shape({})),
    setTitle: PropTypes.func,
    push: PropTypes.func,
    installation: PropTypes.instanceOf(DtoInstallation),
    installations: PropTypes.arrayOf(PropTypes.instanceOf(DtoInstallation)),
    fetchInstallation: PropTypes.func,
    fetchExploitation: PropTypes.func,
    updateExploitation: PropTypes.func,
    fetchCmsByCategory: PropTypes.func,
    createMatEvent: PropTypes.func,
    fetchMatEventsByExploitation: PropTypes.func,
    calculPointVolumeReal: PropTypes.func,
    deleteMatEvent: PropTypes.func,
    updateMatEvent: PropTypes.func,
    resetInstallation: PropTypes.func,
    fetchContacts: PropTypes.func,
    deleteBookmark: PropTypes.func,
    createBookmark: PropTypes.func,
    fetchBookmarks: PropTypes.func,
    exploitation: PropTypes.instanceOf(DtoExploitation),
    variousMateriels: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMateriel)),
    variousMatSituations: PropTypes.arrayOf(PropTypes.instanceOf(DtoVariousMatSituation)),
    exploitationVolumes: PropTypes.arrayOf(PropTypes.instanceOf(DtoDeclarationVolumes)),
    cms: PropTypes.arrayOf(PropTypes.instanceOf(DtoCMSEvent)),
    matEventsExploitation: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEvent)),
    matEventsTypes: PropTypes.arrayOf(PropTypes.instanceOf(DtoMatEventsType)),
    bookmarks: PropTypes.arrayOf(PropTypes.shape({})),
    watersheds: PropTypes.arrayOf(PropTypes.shape({})),
    citiesIndex: PropTypes.instanceOf(PropTypes.shape({})),
    waterTurnsRestrictions: PropTypes.arrayOf(PropTypes.instanceOf(DtoWaterTurnRestriction)),
}

const mapStateToProps = (store) => {
    return {
        installations: store.InstallationsReducer.installations,
        installation: store.InstallationsReducer.installation,
        variousMateriels: store.InstallationsReducer.variousMateriels,
        variousMatSituations: store.InstallationsReducer.variousMatSituations,
        exploitation: store.AgriReducer.exploitation,
        exploitationVolumes: store.AgriReducer.exploitationVolumes,
        cms: store.CmsReducer.cms,
        matEventsExploitation: store.MaterielReducer.matEventsExploitation,
        matEventsTypes: store.MaterielReducer.matEventsTypes,
        bookmarks: store.ReferencialReducer.bookmarks,
        watersheds: store.ReferencialReducer.watersheds,
        citiesIndex: store.CityReducer.citiesIndex,
        waterTurnsRestrictions: store.AgriReducer.waterTurnsRestrictions,
    }
}

const mapDispatchToProps = {
    fetchExploitation: AgriAction.fetchExploitation,
    updateExploitation: AgriAction.updateExploitation,
    fetchModesIrrigations: AgriAction.fetchModesIrrigations,
    fetchInstallation: InstallationsAction.fetchInstallation,
    resetInstallation: InstallationsAction.resetInstallation,
    setTitle: HomeAction.setTitle,
    fetchCmsByCategory: CmsAction.fetchCmsByCategory,
    createMatEvent: MaterielAction.createMatEvent,
    updateMatEvent: MaterielAction.updateMatEvent,
    deleteMatEvent: MaterielAction.deleteMatEvent,
    fetchMatEventsByExploitation: MaterielAction.fetchMatEventsByExploitation,
    calculPointVolumeReal: AgriAction.calculPointVolumeReal,
    fetchContacts: ContactAction.fetchContacts,
    deleteBookmark: ReferencialAction.deleteBookmark,
    createBookmark: ReferencialAction.createBookmark,
    fetchBookmarks: ReferencialAction.fetchBookmarks,
    push,
}

export default connect(mapStateToProps, mapDispatchToProps)(PointConso)
